import React from "react";
import "./notfound.scss";
import { mbNotFound, notFound } from "../../source";
import { Link } from "react-router-dom";
import { homeURL } from "../../helpers/paths";
import { useWindowSize } from "react-use";
import Header from "../../components/Header/Header";
import { useEffect } from "react";

const NotFound = () => {
    const {width} = useWindowSize();

    useEffect(() => {
      window.history.scrollRestoration = 'manual'
    }, []);

  return (
    <>
    <Header />
      <section className="notfound_sec1">
        <div className="row notfound_row">
            <div className="col-lg-5 notfound_col">
                <div className="notfound_content_wrapper">
                    <h2 className="notfound_heading">404</h2>
                    <p className="notfound_desc">The page you were looking for does not exist</p>
                    <div className="notfound_cta_wrapper">
                        <Link to={homeURL} className="notfound_cta">Go To Homepage</Link>
                    </div>
                </div>
            </div>
            <div className="col-lg-7 notfound_col">
                <div className="notfound_img_wrapper">
                    <img src={width > 834 ? notFound : mbNotFound} alt="not found" className="notfound_img"/>
                </div>
            </div>
        </div>
      </section>
    </>
  );
};


export default NotFound;