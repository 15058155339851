import React from "react";
import "./beforeafterslider.scss";
import {
  desktopCompare,
  freshStraberry,
  mbFreshStraberry,
  mbrottenStraberry,
  mobileCompare,
  rottenStraberry,
} from "../../source";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import { useWindowSize } from "react-use";

const BeforeAfterSlider = (props) => {
  const { width } = useWindowSize();

  // const handleSliderChange = (e) => {
  //   const container = document.querySelector(".before_after_sec1");
  //   container.style.setProperty("--position", `${e.target.value}%`);
  // };

  const CustomHandle = () => {
    return (
      <>
        {width <= 767 ? (
          <button
            style={{
              height: "0.8em",
            }}
          />
        ) : null}
        <div
          style={{
            // width: props.portrait ? '0.6875em' : '100%',
            // height: props.portrait ? '100%' : '0.8em',
            width: width > 767 ? "0.6875em" : "100%",
            height: width > 767 ? "100%" : "0.8em",
            backgroundColor: "#1C1C1C",
            cursor: "e-resize",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: `${
              width > 767 ? `url(${desktopCompare})` : `url(${mobileCompare})`
            } no-repeat`,
            backgroundSize: "100% 100%",
            width: width > 767 ? "11.125em" : "4.6em",
            height: width > 767 ? "5.625em" : "9.2em",
            cursor: "e-resize",
          }}
        />
        ;
      </>
    );
  };

  return (
    // <div className="before_after_sec1">
    //   <div className="image_container">
    //     <div className="before_img_wrapper">
    //       <img
    //           className="image_before slider_image"
    //           src={straberry}
    //           alt="color photo"
    //         />
    //       <div className="before_text_wrapper">
    //         <h4 className="title">Ozone Misted</h4>
    //         <p className="desc">No visible mold gorwth even at 7 day </p>
    //       </div>
    //     </div>
    //     <div className="after_img_wrapper">
    //       <img
    //         className="image_after slider_image"
    //         src={straberry}
    //         alt="black and white"
    //       />
    //       <div className="after_text_wrapper">
    //         <h4 className="title">NO Ozone</h4>
    //         <p className="desc">visible mold gorwth at 3 days </p>
    //       </div>
    //     </div>
    //   </div>
    //   <input
    //     type="range"
    //     min="0"
    //     max="100"
    //     value="50"
    //     aria-label="Percentage of before photo shown"
    //     className="slider"
    //     onChange={handleSliderChange}
    //   />
    //   <div className="slider_line" aria-hidden="true"></div>
    //   <div className="slider_button" aria-hidden="true">
    //     {/* <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="30"
    //       height="30"
    //       fill="currentColor"
    //       viewBox="0 0 256 256"
    //     >
    //       <rect width="256" height="256" fill="none"></rect>
    //       <line
    //         x1="128"
    //         y1="40"
    //         x2="128"
    //         y2="216"
    //         fill="none"
    //         stroke="currentColor"
    //         stroke-linecap="round"
    //         stroke-linejoin="round"
    //         stroke-width="16"
    //       ></line>
    //       <line
    //         x1="96"
    //         y1="128"
    //         x2="16"
    //         y2="128"
    //         fill="none"
    //         stroke="currentColor"
    //         stroke-linecap="round"
    //         stroke-linejoin="round"
    //         stroke-width="16"
    //       ></line>
    //       <polyline
    //         points="48 160 16 128 48 96"
    //         fill="none"
    //         stroke="currentColor"
    //         stroke-linecap="round"
    //         stroke-linejoin="round"
    //         stroke-width="16"
    //       ></polyline>
    //       <line
    //         x1="160"
    //         y1="128"
    //         x2="240"
    //         y2="128"
    //         fill="none"
    //         stroke="currentColor"
    //         stroke-linecap="round"
    //         stroke-linejoin="round"
    //         stroke-width="16"
    //       ></line>
    //       <polyline
    //         points="208 96 240 128 208 160"
    //         fill="none"
    //         stroke="currentColor"
    //         stroke-linecap="round"
    //         stroke-linejoin="round"
    //         stroke-width="16"
    //       ></polyline>
    //     </svg> */}
    //     <img src={mbRangeArrow} alt="arrow" />
    //   </div>
    // </div>

    <div className="before_after_sec">
      <ReactCompareSlider
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
          // touchAction: "auto",
        }}
        portrait={width > 767 ? false : true}
        position={50}
        onlyHandleDraggable={true}
        // onlyHandleDraggable={width > 767 ? true : false}
        {...props}
        handle={<CustomHandle />}
        itemOne={
          <ReactCompareSliderImage
            src={width > 767 ? freshStraberry : mbFreshStraberry}
            // srcSet={freshStraberry}
            alt="fresh"
            style={{
              backgroundColor: width <= 767 ? "#ffffff" : "",
            }}
          />
        }
        itemTwo={
          <ReactCompareSliderImage
            src={width > 767 ? rottenStraberry : mbrottenStraberry}
            // srcSet={rottenStraberry}
            alt="rotten"
            style={{
              backgroundColor: width <= 767 ? "#ffffff" : "",
            }}
          />
        }
      />
    </div>
  );
};

export default BeforeAfterSlider;
