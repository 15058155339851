import React from "react";
import "./comingsoon.scss";
import { homeURL } from "../../helpers/paths";

import { commingsoon, diamistLogo, mbCommingsoon } from "../../source";
import { Link } from "react-router-dom";
import { useWindowSize } from "react-use";

const Comingsoon = () => {
  const { width } = useWindowSize();
  return (
    <>
      <section className="commingsoon_sec1">
        <div className="logo_wrapper">
          <div className="commingsoon_contianer">
            <Link to={homeURL}>
              <img src={diamistLogo} alt="logo" className="logo_img" />
            </Link>
          </div>
        </div>
        {width > 767 ? (
          <div className="commingsoon_contianer">
            <div className="row commingsoon_row">
              <div className="col-lg-5 commingsoon_col">
                <div className="commingsoon_text_wrapper">
                  <h2 className="text">We're</h2>
                  <h1 className="heading">
                    COMING <br /> SOON!
                  </h1>
                </div>
              </div>
              <div className="col-lg-7 commingsoon_col">
                <div className="image_wrapper">
                  <img
                    src={commingsoon}
                    alt="commingsoon"
                    className="comminsoon_img"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row commingsoon_row">
            <div className="col-lg-5 commingsoon_col">
              <div className="commingsoon_contianer">
                <div className="commingsoon_text_wrapper">
                  <h2 className="text">We're</h2>
                  <h1 className="heading">
                    COMING <br /> SOON!
                  </h1>
                </div>
              </div>
            </div>
            <div className="col-lg-7 commingsoon_col">
              <div className="image_wrapper">
                <img
                  src={mbCommingsoon}
                  alt="commingsoon"
                  className="comminsoon_img"
                />
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};
export default Comingsoon;
