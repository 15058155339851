import { images } from "../source";

export const prototypeData = [
  {
    id: 1,
    img: images.prototypeSlider1.image,
    alt: images.prototypeSlider1.alt,
    title: "POD",
    desc: `Acts as a diffusers & humidifier to create a pleasant environment.`,
  },
  {
    id: 2,
    img: images.prototypeSlider2.image,
    alt: images.prototypeSlider2.alt,
    title: "POD + Small Bucket",
    desc: `Small bucket is perfect to disinfect and cleanse small & personal items.`,
  },
  {
    id: 3,
    img: images.prototypeSlider3.image,
    alt: images.prototypeSlider3.alt,
    title: "POD + Big Bucket",
    desc: `Big bucket can be used to disinfect a host of larger items and multiple things.`,
  },
];

export const productData = [
  {
    id: 1,
    tab: "INDUSTRY USES",
    products: [
      {
        id: 1,
        img: images.industrySlider1.image,
        alt: images.industrySlider1.alt,
      },
      {
        id: 2,
        img: images.industrySlider2.image,
        alt: images.industrySlider2.alt,
      },
      {
        id: 3,
        img: images.industrySlider3.image,
        alt: images.industrySlider3.alt,
      },
    ],
  },
  {
    id: 2,
    tab: "HOUSEHOLD USES",
    products: [
      {
        id: 1,
        img: images.houseSlider1.image,
        alt: images.houseSlider1.alt,
      },
      {
        id: 2,
        img: images.houseSlider2.image,
        alt: images.houseSlider2.alt,
      },
      {
        id: 3,
        img: images.houseSlider3.image,
        alt: images.houseSlider3.alt,
      },
      {
        id: 4,
        img: images.houseSlider4.image,
        alt: images.houseSlider4.alt,
      },
      {
        id: 5,
        img: images.houseSlider5.image,
        alt: images.houseSlider5.alt,
      },
      {
        id: 6,
        img: images.houseSlider6.image,
        alt: images.houseSlider6.alt,
      },
    ],
  },
];
