import React, { useState, useEffect } from "react";
import "./Header.scss";
import { useWindowSize } from "react-use";
import { homeURL } from "../../helpers/paths";
import { diamistLogo, downloadPdf } from "../../source";
import { Link } from "react-router-dom";
import { Button } from "react-scroll";
import HeaderNavItem from "./HeaderNavItem";
const body = document.querySelector("body");

const navLinksArr = [
  {
    id: 1,
    type: "img",
    img: diamistLogo,
    bgImg: diamistLogo,
    mainLink: "/",
    class: "logo_img",
  },
  {
    id: 2,
    type: "text",
    mainTitle: "Working",
    mainLink: homeURL,
    scrollid: "working",
  },
  {
    id: 3,
    type: "text",
    mainTitle: "Uses",
    mainLink: homeURL,
    scrollid: "uses",
  },
  {
    id: 4,
    type: "text",
    mainTitle: "Test Shelf Life",
    mainLink: homeURL,
    scrollid: "shelf",
  },
  {
    id: 5,
    type: "text",
    mainTitle: "Downloads",
    mainLink: downloadPdf,
    linkType: "external",
  },
];

const mobNavLinksArr = [
  {
    id: 1,
    type: "text",
    mainTitle: "Working",
    mainLink: homeURL,
    scrollid: "working",
  },
  {
    id: 2,
    type: "text",
    mainTitle: "Uses",
    mainLink: homeURL,
    scrollid: "uses"
  },
  {
    id: 3,
    type: "text",
    mainTitle: "Test Shelf Life",
    mainLink: homeURL,
    scrollid: "shelf",
  },
  {
    id: 4,
    type: "text",
    mainTitle: "Downloads",
    mainLink: downloadPdf,
    linkType: "external",
  },
];

const Header = () => {
  const { width } = useWindowSize();
  const [navlinkIndex, setNavlinkIndex] = useState(null);
  const [isBg, setIsbg] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [navActiveIndex, setNavActiveIndex] = useState(null);

  const scrollHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  });

  const handleScroll = () => {
    if (window.location.pathname === "/")
      if (window.pageYOffset > 60) {
        setIsbg(true);
      } else {
        setIsbg(false);
      }
  };

  const handleNavIndex = (i) => {
    if (navActiveIndex === i) {
      setNavActiveIndex(null);
    } else {
      setNavActiveIndex(i);
    }
  };

  const handleHamburgerClick = () => {
    setIsMenuActive(!isMenuActive);
    if (!isMenuActive) {
      // Disable scroll
      body.style.overflow = "hidden";
    } else {
      // Enable scroll
      body.style.overflow = "auto";
    }
  };

  const navlinkList = navLinksArr.map((nav, i) => (
    <li key={i}>
      {nav.type === "text" ? (
        <div className="tab_wrapper">
          {nav.linkType === "external" ? (
            <a
              href={nav.mainLink}
              target="_blank"
              rel="noreferrer"
              className={`nav_tab ${navlinkIndex === i ? "active" : ""}`}
              onClick={() => setNavlinkIndex(i)}
            >
              {nav.mainTitle}
            </a>
          ) : (
            <Button
              style={{
                border: "none",
                backgroundColor: "transparent",
              }}
              to={nav.scrollid}
              smooth={true}
              spy={true}
              offset={0}
              duration={1}
            >
              <Link
                to={nav.mainLink}
                className={`nav_tab ${navlinkIndex === i ? "active" : ""}`}
                onClick={() => setNavlinkIndex(i)}
              >
                {nav.mainTitle}
              </Link>
            </Button>
          )}
        </div>
      ) : (
        <div
          className={`logo_wrapper ${navlinkIndex === i ? "" : ""}`}
          onClick={() => setNavlinkIndex(i)}
        >
          <Link to={homeURL}>
            <img
              className={nav.class}
              src={isBg ? nav.bgImg : nav.img}
              alt="Kapindra Logo"
              onClick={scrollHandler}
            />
          </Link>
        </div>
      )}
    </li>
  ));

  return (
    <>
      <section className={`header_sec1 ${isBg ? "navbar_bg" : ""}`}>
        {width > 767 ? (
          <div className="my_container">
            <div className="navlinks">
              <ul className="nav_list">{navlinkList}</ul>
            </div>
          </div>
        ) : (
          <header className="header">
            <div className="my_container">
              <div className="logo_wrapper">
                <Link className="logo_link" to={homeURL}>
                  <img
                    src={isBg ? diamistLogo : diamistLogo}
                    alt="diamist logo"
                    className="diamist_logo img-fluid"
                  />
                </Link>
              </div>
            </div>
            <nav className="nav_links_wrapper">
              <div
                className={`nav_line2_wrapper_flex_wrapper ${
                  isMenuActive ? "active" : ""
                }`}
              >
                <div className="logo_wrapper">
                  <Link className="logo_link" to={homeURL}>
                    <img
                      src={`${isMenuActive ? diamistLogo : diamistLogo}`}
                      alt="diamist logo"
                      className="diamist_logo_1 img-fluid"
                    />
                  </Link>
                </div>
                <ul className="nav_line2_wrapper_flex">
                  {mobNavLinksArr.map((navData, i) => (
                    <HeaderNavItem
                      navData={navData}
                      key={parseInt(navData.id)}
                      arrIndex={i}
                      handleNavIndex={handleNavIndex}
                      navActiveIndex={navActiveIndex}
                      setNavActiveIndex={setNavActiveIndex}
                      handleHamburgerClick={handleHamburgerClick}
                    />
                  ))}
                </ul>
              </div>
            </nav>

            {/* mobile UI start */}
            <div
              className={`hamburger_lines ${
                isMenuActive ? "active" : "" || isBg ? "black" : ""
              }`}
              onClick={handleHamburgerClick}
            >
              <div className="line line1"></div>
              <div className="line line2"></div>
              <div className="line line3"></div>
            </div>
          </header>
        )}
      </section>
    </>
  );
};

export default Header;
