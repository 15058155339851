import React, { useEffect } from "react";
import "./thankyou.scss";
import Header from "../../components/Header/Header";
import { Link } from "react-router-dom";
import { homeURL } from "../../helpers/paths";
import { mbThankYou, thankYou } from "../../source";
import { useWindowSize } from "react-use";

const ThankYou = () => {
    const {width} = useWindowSize();

    useEffect(() => {
      window.history.scrollRestoration = 'manual'
    }, []);

  return (
    <>
    <Header />
      <section className="thank_sec1">
        <div className="row thank_row">
            <div className="col-lg-5 thank_col">
                <div className="thank_content_wrapper">
                    <h2 className="thank_heading">THANK <br/> YOU!</h2>
                    <p className="thank_desc">Your message has been received, we will update you shortly.</p>
                    <div className="thank_cta_wrapper">
                        <Link to={homeURL} className="thank_cta">Go To Homepage</Link>
                    </div>
                </div>
            </div>
            <div className="col-lg-7 thank_col">
                <div className="thank_img_wrapper">
                    <img src={width > 834 ? thankYou : mbThankYou} alt="thank you" className="thank_img"/>
                </div>
            </div>
        </div>
      </section>
    </>
  );
};

export default ThankYou;
