import React from "react";
import "./footer.scss";
import { downloadPdf, mbScrollUpArrow, scrollUpArrow } from "../../source";
import { useWindowSize } from "react-use";

const Footer = () => {
  const { width } = useWindowSize();
  const year = new Date();

  const scrollHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <footer className="footer_sec1">
        <div className="my_container">
          <img
            src={width > 767 ? scrollUpArrow : mbScrollUpArrow}
            alt="up arrow"
            onClick={scrollHandler}
            className="scroll_up"
          />
          {width <= 767 ? (
            <div className="footer_content_wrapper">
              <a
                className="text"
                href={downloadPdf}
                target="_blank"
                rel="noreferrer"
              >
                Downloads
              </a>
            </div>
          ) : null}
          <div className="copy_right_wrapper">
            <p className="copy_right">
              Kapindra, All Rights Reserved - {year.getFullYear()}{" "}
              {width > 767 ? "|" : ""}{" "}
              <a
                href="https://www.togglehead.in/"
                target="_blank"
                rel="noreferrer"
                className="copyright_link"
              >
                Crafted by Togglehead
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
