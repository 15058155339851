// general
export { default as diamistLogo } from "./images/general/diamist_logo.svg";
export { default as blackRightArrow } from "./images/general/black_right_arrow.png";
export { default as scrollUpArrow } from "./images/general/footer_up_arrow.png";
export { default as mbScrollUpArrow } from "./images/general/mb_footer_up_arrow.png";
export { default as mbRangeArrow } from "./images/general/mb_range_arrow.png";
export { default as downloadPdf } from "./images/general/Download_Tab.pdf";
export { default as desktopCompare } from "./images/general/range_arrow.png";
export { default as mobileCompare } from "./images/general/mb_range_arrow_2.png";

// Comming soon
export { default as commingsoon } from "./images/commingsoon/comming_soon.png";
export { default as mbCommingsoon } from "./images/commingsoon/mb_commingsoon .png";

// Homepage
export { default as bannerVideo } from "./images/home/padmanabh-diamist-product-animation_1.mp4";
export { default as sectionVideo } from "./images/home/padmanabh-aquatech-2023-awards-video_1.mp4";
export { default as videoBanner } from "./images/home/videobanner.png";
export { default as playButton } from "./images/home/play-circle-svgrepo-com.png";
export { default as leftArrow } from "./images/home/left_arrow.png";
export { default as rigthArrow } from "./images/home/right_arrow.png";
export { default as mbLeftArrow } from "./images/home/mb_left_arrow.png";
export { default as mbRigthArrow } from "./images/home/mb_right_arrow.png";
export { default as shelf } from "./images/home/shelf.png";
export { default as freshStraberry } from "./images/home/fresh_straberry.jpg";
export { default as mbFreshStraberry } from "./images/home/mb_fresh_straberry.png";
export { default as rottenStraberry } from "./images/home/rotten_straberry.jpg";
export { default as mbrottenStraberry } from "./images/home/mb_rotten_straberry.png";

// thank you
export { default as thankYou } from "./images/thankyou/thank_you.png";
export { default as mbThankYou } from "./images/thankyou/mobile_thank_you.png";

// 404
export { default as notFound } from "./images/notfound/not_found.png";
export { default as mbNotFound } from "./images/notfound/mb_not_found.png";

export let images = {
  prototypeSlider1: {
    image: require("./images/home/pod.png"),
    alt: "Padmanabh Product",
  },
  prototypeSlider2: {
    image: require("./images/home/Padmanabh_Product.png"),
    alt: "Padmanabh Product",
  },
  prototypeSlider3: {
    image: require("./images/home/pod_big_bucket.png"),
    alt: "Padmanabh Product",
  },
  industrySlider1: {
    image: require("./images/home/disinfection_tools.png"),
    alt: "disinfection of tools",
  },
  industrySlider2: {
    image: require("./images/home/disinfection_cutlery.png"),
    alt: "disinfection of cutlery",
  },
  industrySlider3: {
    image: require("./images/home/disinfection_medical.png"),
    alt: "disinfection of medical",
  },
  houseSlider1: {
    image: require("./images/home/household_1.jpg"),
    alt: "disinfection of tools",
  },
  houseSlider2: {
    image: require("./images/home/household_2.jpg"),
    alt: "disinfection of cutlery",
  },
  houseSlider3: {
    image: require("./images/home/household_3.jpg"),
    alt: "disinfection of medical",
  },
  houseSlider4: {
    image: require("./images/home/household_4.jpg"),
    alt: "disinfection of medical",
  },
  houseSlider5: {
    image: require("./images/home/household_5.jpg"),
    alt: "disinfection of medical",
  },
  houseSlider6: {
    image: require("./images/home/household_6.jpg"),
    alt: "disinfection of medical",
  },
};
