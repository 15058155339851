import React from "react";
import { NavLink } from "react-router-dom";
import { Button } from "react-scroll";

const HeaderNavItem = ({
  navData,
  navActiveIndex,
  handleNavIndex,
  arrIndex,
  handleHamburgerClick,
}) => {
  //   if (navData.dropDownLevel === 1) {
  //     return (
  //       <li className="nav_item">
  //         <div
  //           className="nav_link_wrapper"
  //           onClick={() => handleNavIndex(arrIndex)}
  //         >
  //           <span
  //             className={`nav_link ${
  //               navActiveIndex === arrIndex ? "active" : ""
  //             }`}
  //           >
  //             {navData.mainTitle}
  //           </span>
  //           {width >= 767 ? (
  //             <img
  //               src={whiteRightArrow}
  //               alt="Down arrow"
  //               className="headerdownarrow"
  //               loading="lazy"
  //             />
  //           ) : (
  //             <span className="headerdownarrow">
  //               {navActiveIndex === arrIndex ? (
  //                 <img
  //                   src={greenRightArrow}
  //                   alt="down arrow img"
  //                   loading="lazy"
  //                 />
  //               ) : (
  //                 <img
  //                   src={whiteRightArrow}
  //                   className="down_arrow"
  //                   alt="down arrow img"
  //                   loading="lazy"
  //                 />
  //               )}
  //             </span>
  //           )}
  //         </div>
  //         <div
  //           className={`dropdown_box ${
  //             navActiveIndex === arrIndex ? "active" : ""
  //           }`}
  //         >
  //           {width >= 767 && (
  //             <img
  //               src={whiteRightArrow}
  //               alt="dropdown box up arrow"
  //               loading="lazy"
  //               className="dropdownboxuparrow"
  //             />
  //           )}
  //           {navData.dropDownList.map((data, i) => (
  //             <div className="dropdown_list_item" key={data.id}>
  //               <NavLink
  //                 className={({ isActive }) =>
  //                   isActive
  //                     ? "dropdown_list_item_link active"
  //                     : "dropdown_list_item_link"
  //                 }
  //                 to={data.navLink}
  //               >
  //                 {data.navTitle}
  //               </NavLink>
  //             </div>
  //           ))}
  //         </div>
  //       </li>
  //     );
  //   }

  return (
    <li className="nav_item">
      {navData.linkType === "external" ? (
        <a
          className="nav_link"
          href={navData.mainLink}
          target="_blank"
          rel="noreferrer"
          onClick={handleHamburgerClick}
        >
          {navData.mainTitle}
        </a>
      ) : (
        <Button
          style={{
            border: "none",
            backgroundColor: "transparent",
          }}
          to={navData.scrollid}
          smooth={true}
          spy={true}
          offset={0}
          duration={1}
          onClick={handleHamburgerClick}
        >
          <NavLink
            className={({ isActive }) =>
              isActive ? "nav_link active" : "nav_link"
            }
            to={navData.mainLink}
          >
            {navData.mainTitle}
            {/* <img
          src={whiteRightArrow}
          alt="arrow"
          loading="lazy"
          className="not_active_arrow"
        />
        <img
          src={greenRightArrow}
          alt="arrow"
          loading="lazy"
          className="active_arrow"
        /> */}
          </NavLink>
        </Button>
      )}
    </li>
  );
};

export default HeaderNavItem;
