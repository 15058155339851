import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Comingsoon from "./pages/Comingsoon/Comingsoon";
import {
  commingsoonURL,
  homeURL,
  notFoundURL,
  thankYouURL,
} from "./helpers/paths";
import Homepage from "./pages/Homepage/Homepage";
import ThankYou from "./pages/ThankYou/ThankYou";
import NotFound from "./pages/NotFound/NotFound";

const Routing = () => {
  return (
    <>
      <Routes>
        <Route exact path={homeURL} element={<Homepage />} />
        <Route exact path={commingsoonURL} element={<Comingsoon />} />
        <Route exact path={thankYouURL} element={<ThankYou />} />
        <Route exact path={notFoundURL} element={<NotFound />} />
        <Route path="*" element={<Navigate to={notFoundURL} />} />
      </Routes>
    </>
  );
};

export default Routing;
