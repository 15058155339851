import React, { useState, useEffect } from "react";
import "./homepage.scss";
import {
  bannerVideo,
  leftArrow,
  mbLeftArrow,
  mbRigthArrow,
  rigthArrow,
  sectionVideo,
} from "../../source";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { productData, prototypeData } from "../../data/homepageData";
import EnquireNow from "../../components/EnquireNow/EnquireNow";
import Footer from "../../components/Footer/Footer";
import BeforeAfterSlider from "../../components/BeforeAfterSlider/BeforeAfterSlider";
import Header from "../../components/Header/Header";
import { useWindowSize } from "react-use";
// import FsLightbox from "fslightbox-react";

const Homepage = () => {
  const { width } = useWindowSize();
  const [isTab, setIsTab] = useState(0);
  // const [toggler, setToggler] = useState(false);

  const prototypeList = prototypeData.map((item, i) => (
    <SwiperSlide key={i}>
      <div className="row prototype_row">
        <div className="col-lg-6 prototype_col order-md-1">
          <div className="img_wrapper">
            <img src={item.img} alt={item.alt} className="protpype_img" width={724} height={698}/>
          </div>
        </div>
        <div className="col-lg-6 prototype_col order-md-2">
          <div className="prototype_content_wrapper">
            {/* <p className="number">{item.id < 10 ? "0" + item.id : item.id}</p> */}
            <h4 className="title">
              <span className="number">
                {item.id < 10 ? "0" + item.id : item.id}
              </span>
              {item.title}
            </h4>
            {item.desc !== "" ? (
              <p
                className="desc"
                dangerouslySetInnerHTML={{ __html: item.desc }}
              ></p>
            ) : null}
          </div>
        </div>
      </div>
    </SwiperSlide>
  ));

  const productTabList = productData.map((item, i) => (
    <div className="tab_wrapper" onClick={() => setIsTab(i)} key={i}>
      <h3 className={`tab_name ${isTab === i ? "active" : ""}`}>{item.tab}</h3>
    </div>
  ));

  const prototypeContentList = productData[isTab]["products"].map((item, i) => {
    return (
      <SwiperSlide key={i}>
        <div className="product_img_wrapper">
          <img src={item.img} alt={item.alt} className="product_img" />
        </div>
      </SwiperSlide>
    );
  });

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, []);

  return (
    <>
      <Header />
      <section className="home_sec1">
        <div className="banner_wrapper">
          <video
            className="banner_video"
            width="100%"
            autoPlay
            muted
            loop
            playsInline
          >
            <source
              className="video_source"
              src={bannerVideo}
              type="video/mp4"
            />
          </video>
          {/* <img
            src={playButton}
            alt="play button"
            className="play_button"
            loading="lazy"
          /> */}
        </div>
      </section>

      <section className="home_sec2" id="working">
        <div className="my_container">
          <div className="prototype_wrapper">
            <h2 className="heading">Working Prototype</h2>
            <p className="desc">
              Diamist is an innovative water-based disinfection device that uses
              Diamond powered technology to convert water into a potent
              disinfectant without the use of any chemical cleansing or process
              enhancing agents.
            </p>
          </div>
        </div>
        <div className="prototype_slider_wrapper">
          <div className="my_container">
            <Swiper
              spaceBetween={0}
              //   loop={true}
              modules={[Navigation]}
              slidesPerView={1}
              navigation={{
                prevEl: ".prototype_prev",
                nextEl: ".prototype_next",
              }}
              className="prototype_swiper"
              breakpoints={{
                0: {
                  autoHeight: true,
                },
                768: {
                  autoHeight: false,
                },
              }}
            >
              {prototypeList}
            </Swiper>
          </div>
          <div className="prototype_arrow">
            <img
              src={width > 767 ? leftArrow : mbLeftArrow}
              alt="left arrow"
              className="prototype_prev"
            />
            <img
              src={width > 767 ? rigthArrow : mbRigthArrow}
              alt="right arrow"
              className="prototype_next"
            />
          </div>
        </div>
      </section>

      <section className="home_sec3">
        <div className="innovation_wrapper">
          <video
            className="innovation_video"
            width="100%"
            autoPlay
            muted
            loop
            playsInline
            // controls
          >
            <source
              className="video_source"
              src={sectionVideo}
              type="video/mp4"
            />
          </video>
          {/* <img
            src={playButton}
            alt="play button"
            className="innovation_button"
            loading="lazy"
          /> */}

          {/* Fslightbox start here */}
          {/* <img
            src={width > 767 ? videoBanner : videoBanner}
            alt="video banner"
            className="video_banner"
          />
          <img
            src={playButton}
            alt="play button"
            className="innovation_button"
            loading="lazy"
            onClick={() => setToggler(!toggler)}
          /> */}
          {/* Fslightbox end here */}
        </div>
      </section>

      <section className="home_sec4" id="uses">
        <div className="my_container">
          <h2 className="heading">Product Uses</h2>
          <div className="tab_conatiner">{productTabList}</div>
        </div>
        <div className="tab_slider_container">
          <div className="my_container">
            <Swiper
              spaceBetween={16}
              modules={[Navigation]}
              slidesPerView={3}
              // loop={true}
              // swiperInstanceParamsObserveParents={true}
              // waitForTransition={true}
              onSlideChange={(swiperCore) => {
                const { activeIndex } = swiperCore;
                console.log("product changed", { activeIndex });
              }}
              // swiperReference = useRef(null)
              // swiperInstanceUpdate={true}
              navigation={{
                prevEl: ".product_prev",
                nextEl: ".product_next",
              }}
              className="product_swiper"
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 16,
                },
                1023: {
                  slidesPerView: 3,
                  spaceBetween: 16,
                },
              }}
            >
              {prototypeContentList}
            </Swiper>
          </div>
          <div className="product_arrow">
            <img
              src={width > 767 ? leftArrow : mbLeftArrow}
              alt="left arrow"
              className="product_prev"
            />
            <img
              src={width > 767 ? rigthArrow : mbRigthArrow}
              alt="right arrow"
              className="product_next"
            />
          </div>
        </div>
      </section>

      <section className="home_sec5" id="shelf">
        <div className="my_container">
          <div className="shelf_text_container">
            <h2 className="heading">Test Shelf Life Extension</h2>
            <p className="desc">
              Fresh vegetables and fruits were divided into two separate
              chambers; One with ‘no ozone’ and another with ozone misting for 5
              mins every 3 hours using Kreova’s DIAMIST POD device.
            </p>
          </div>
          {/* <div className="shelf_img_container">
            <img src={shelf} alt="shelf" className="shelf_img" />
            <BeforeAfterSlider beforeImage={commingsoon} afterImage={videoBanner} />
          </div> */}
          <BeforeAfterSlider
            // beforeImage={commingsoon}
            // afterImage={videoBanner}
          />
        </div>
      </section>

      {/* <section className="fslightbox_container">
        <FsLightbox
          toggler={toggler}
          sources={[
            "https://www.youtube.com/watch?v=yple9O14gwU&ab_channel=Titax",
          ]}
        />
      </section> */}

      <EnquireNow />
      <Footer />
    </>
  );
};

export default Homepage;
